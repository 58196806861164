import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import {
  EntityState,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import { PatchCollection } from "@reduxjs/toolkit/dist/query/core/buildThunks";
import { addYears } from "date-fns";
import { z } from "zod";
import {
  ApplicationStatus,
  CardStatus,
  PaymentStatus,
  Role,
  Title,
} from "../typings/enums";
import { RootState } from "./store";

const baseUrl: string = import.meta.env.VITE_API_URL;

export const getOneUserResponseSchema = z.object({
  address: z.string(),
  applicationStatus: z.nativeEnum(ApplicationStatus),
  cardId: z.string(),
  cardStatus: z.nativeEnum(CardStatus),
  org: z.string(),
  comment: z.string(),
  dateCreated: z.string(),
  dateOfBirth: z.number(),
  email: z.string(),
  firstname: z.string(),
  hasPayedOnline: z.boolean(),
  id: z.string(),
  role: z.nativeEnum(Role).optional(),
  transactionId: z.string(),
  transactionDate: z.union([z.number(), z.null()]),
  image: z.string(),
  rokkaHash: z.string().optional(),
  lastname: z.string(),
  paymentStatus: z.nativeEnum(PaymentStatus),
  awaitingReply: z.boolean(),
  phone: z.string(),
  postalCode: z.number(),
  title: z.nativeEnum(Title),
  orgAdmin: z.string().optional(),
  validUntil: z.union([z.number(), z.null()]),
});

export const createUserRequestSchema = z.object({
  user: z.object({
    address: z.string(),
    org: z.string(),
    comment: z.string(),
    dateOfBirth: z.number(),
    email: z.string(),
    firstname: z.string(),
    dateCreated: z.string().optional(),
    rokkaHash: z.string().optional(),
    lastname: z.string(),
    phone: z.string(),
    postalCode: z.number(),
    title: z.nativeEnum(Title),
    image: z.string(),
    role: z.nativeEnum(Role).optional(),
    orgAdmin: z.string().optional(),
    validUntil: z.union([z.number(), z.null()]),
  }),
  orgId: z.string(),
});

export const createUserResponseSchema = z.object({
  user: getOneUserResponseSchema,
  oneTimePassword: z.string(),
});

export const updateUserResponseSchema = z.object({
  address: z.string(),
  dateCreated: z.string(),
  rokkaHash: z.string().optional(),
  comment: z.string(),
  dateOfBirth: z.number(),
  email: z.string(),
  firstname: z.string(),
  lastname: z.string(),
  phone: z.string(),
  postalCode: z.number(),
  title: z.nativeEnum(Title),
  image: z.string(),
  cardId: z.string(),
  cardStatus: z.nativeEnum(CardStatus),
  applicationStatus: z.nativeEnum(ApplicationStatus),
  paymentStatus: z.nativeEnum(PaymentStatus),
  awaitingReply: z.boolean(),
  validUntil: z.union([z.number(), z.null()]),
  orgAdmin: z.string().optional(),
  role: z.nativeEnum(Role).optional(),
});

export const updateUserRequestSchema = updateUserResponseSchema.partial();

export const getManyUsersResponseElementSchema = getOneUserResponseSchema.pick({
  id: true,
  firstname: true,
  lastname: true,
  dateOfBirth: true,
  image: true,
  dateCreated: true,
  rokkaHash: true,
  applicationStatus: true,
  cardStatus: true,
  paymentStatus: true,
  comment: true,
  postalCode: true,
  cardId: true,
  email: true,
  address: true,
  awaitingReply: true,
  validUntil: true,
  org: true,
  orgAdmin: true,
  role: true,
});

export const getManyUsersResponseSchema = z.array(
  getManyUsersResponseElementSchema
);
export const getManyUsersRequestSchema = z.array(z.string());

export const getMeResponseSchema = z.object({
  orgName: z.string(),
  orgId: z.string(),
  postalCodes: z.object({}),
  role: z.nativeEnum(Role),
  profile: z.object({
    firstName: z.string(),
    lastName: z.string(),
    displayName: z.string(),
    preferredLanguage: z.string().optional(),
  }),
  neighbours:  z.object({})
});

export const updateManyUsersResponseSchema = z.object({
  applicationStatus: z.nativeEnum(ApplicationStatus).optional(),
  cardStatus: z.nativeEnum(CardStatus).optional(),
  paymentStatus: z.nativeEnum(PaymentStatus).optional(),
  awaitingReply: z.boolean().optional(),
  ids: z.array(z.string()),
});

export const searchUserRequestSchema = z.object({
  firstname: z.string(),
  lastname: z.string(),
  dateOfBirth: z.number(),
});

export const searchUserResponseSchema = z.array(
  getManyUsersResponseElementSchema
);

export type SearchUserRequestDTO = z.infer<typeof searchUserRequestSchema>;
export type SearchUserResponseDTO = z.infer<typeof searchUserResponseSchema>;

export type GetManyUsersRequestDTO = z.infer<typeof getManyUsersRequestSchema>;
export type GetManyUsersResponseDTO = z.infer<
  typeof getManyUsersResponseSchema
>;
export type GetManyUsersResponseElementDTO = z.infer<
  typeof getManyUsersResponseElementSchema
>;

export type GetOneUserResponseDTO = z.infer<typeof getOneUserResponseSchema>;
export type CreateUserRequestDTO = z.infer<typeof createUserRequestSchema>;
export type CreateUserResponseDTO = z.infer<typeof createUserResponseSchema>;
export type UpdateUserRequestDTO = z.infer<typeof updateUserRequestSchema>;
export type UpdateUserResponseDTO = z.infer<typeof updateUserResponseSchema>;
export type UpdateManyUsersRequestDTO = z.infer<
  typeof updateManyUsersResponseSchema
>;
export type MoveUserRequestDTO = Pick<
  GetOneUserResponseDTO,
  "address" | "postalCode" | "applicationStatus"
>;
export type GetMeResponseDTO = z.infer<typeof getMeResponseSchema>;

function getToken() {
  const storedToken = localStorage.getItem("token");

  if (!storedToken) {
    throw new Error("No user found");
  }

  return storedToken;
}

const usersAdapter = createEntityAdapter<GetManyUsersResponseElementDTO>({
  selectId: (user) => user.id,
});

const initialState = usersAdapter.getInitialState();

export const usersApi = createApi({
  reducerPath: "usersApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      headers.set("Authorization", `Bearer ${ getToken()}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    search: builder.query<SearchUserResponseDTO, SearchUserRequestDTO>({
      // TODO: Fix TS here. For some reason the pre-commit hook doesn't like this _at all_.
      query: (query) => {
        const queryString = Object.entries(query)
          .map(([key, value]) => `${key}=${value}`)
          .join("&");
        return {
          url: `users/search/?${queryString}`,
          method: "GET",
        };
      },
    }),
    createUser: builder.mutation<CreateUserResponseDTO, CreateUserRequestDTO>({
      query: (data) => ({
        url: "users/create",
        method: "POST",
        body: data,
      }),
      onQueryStarted: async (
        { user }: CreateUserRequestDTO,
        { dispatch, queryFulfilled }
      ) => {
        const getUsersPatchResult = dispatch(
          usersApi.util.updateQueryData(
            "getUsers",
            undefined,
            (draft: EntityState<GetManyUsersResponseElementDTO>) => {
              return {
                ...draft,
                entities: {
                  ...draft.entities,
                  // The object is created with a provisional id == "0"
                  [0]: {
                    id: "0",
                    firstname: user.firstname,
                    lastname: user.lastname,
                    dateOfBirth: user.dateOfBirth,
                    applicationStatus: ApplicationStatus.Pending,
                    cardStatus: CardStatus.NotPrinted,
                    paymentStatus: PaymentStatus.Unpaid,
                    awaitingReply: false,
                    comment: user.comment,
                    org: user.org,
                    postalCode: user.postalCode,
                    cardId: "",
                    email: user.email,
                    address: user.address,
                    validUntil: user.validUntil,
                    image: user.image,
                    rokkaHash: user.rokkaHash,
                    dateCreated: user.dateCreated ?? '',
                  },
                },
              };
            }
          )
        );

        queryFulfilled.then((res) => {
          dispatch(
            usersApi.util.updateQueryData(
              "getUsers",
              undefined,
              (draft: EntityState<GetManyUsersResponseElementDTO>) => {
                const { 0: defaultItem, ...rest } = draft.entities;

                return {
                  ...draft,
                  ids: [
                    ...draft.ids.filter((id) => id !== 0),
                    res.data.user.id,
                  ],
                  entities: {
                    ...rest,
                    [res.data.user.id]: res.data.user,
                  },
                };
              }
            )
          );
        });

        queryFulfilled.catch(getUsersPatchResult.undo);
      },
    }),
    getUsers: builder.query<EntityState<GetManyUsersResponseElementDTO>, void>({
      query: () => ({ url: "users" }),
      transformResponse: (response: GetManyUsersResponseDTO) =>
        usersAdapter.setAll(initialState, response),
    }),
    getManyUsersByIds: builder.mutation<
      GetManyUsersResponseDTO,
      GetManyUsersRequestDTO
    >({
      query: (ids) => {
        const idArgs = ids.map((id) => `ids[]=${id}`);

        return {
          url: "/users/byId/?" + idArgs.join("&"),
        };
      },
    }),
    getUser: builder.query<GetOneUserResponseDTO, string>({
      query: (id) => ({ url: `users/${id}` }),
    }),
    updateUser: builder.mutation<
      UpdateUserResponseDTO,
      { id: string; data: UpdateUserRequestDTO }
    >({
      query: ({ id, data }) => ({
        url: `users/${id}`,
        method: "PATCH",
        body: data,
      }),
      onQueryStarted: async (
        { id, data }: { id: string; data: UpdateUserRequestDTO },
        { dispatch, queryFulfilled }
      ) => {
        const getUsersPatchResult = dispatch(
          usersApi.util.updateQueryData(
            "getUsers",
            undefined,
            (draft: EntityState<GetManyUsersResponseElementDTO>) => {
              const item = draft.entities[id as keyof typeof draft.entities];

              if (!item) {
                return draft;
              }

              return {
                ...draft,
                entities: {
                  ...draft.entities,
                  [id as keyof typeof draft.entities]: {
                    dateCreated: data?.dateCreated ?? item?.dateCreated,
                    rokkaHash: data?.rokkaHash ?? item?.rokkaHash,
                    applicationStatus:
                      data?.applicationStatus ?? item?.applicationStatus,
                    cardStatus: data?.cardStatus ?? item?.cardStatus,
                    awaitingReply: data?.awaitingReply ?? item?.awaitingReply,
                    paymentStatus: data?.paymentStatus ?? item?.paymentStatus,
                    dateOfBirth: data?.dateOfBirth ?? item?.dateOfBirth,
                    firstname: data?.firstname ?? item?.firstname,
                    id: item?.id,
                    lastname: data?.lastname ?? item?.lastname,
                    comment: data?.comment ?? item?.comment,
                    postalCode: data?.postalCode ?? item?.postalCode,
                    cardId: data?.cardId ?? item?.cardId,
                    email: data?.email ?? item?.email,
                    address: data?.address ?? item?.address,
                    validUntil: data?.validUntil ?? item?.validUntil,
                    image: data?.image ?? item?.image,
                    org: item?.org,
                  },
                },
              };
            }
          )
        );

        const getUserPatchResult = dispatch(
          usersApi.util.updateQueryData(
            "getUser",
            id,
            (draft: GetOneUserResponseDTO) => {
              if (draft.cardId !== data.cardId) {
                data["validUntil"] = addYears(new Date(), 5).getTime();
              }
              return { ...draft, ...data };
            }
          )
        );

        queryFulfilled.catch(getUserPatchResult.undo);
        queryFulfilled.catch(getUsersPatchResult.undo);
      },
    }),
    moveUser: builder.mutation<
      UpdateUserResponseDTO,
      { id: string; data: MoveUserRequestDTO }
    >({
      query: ({ id, data }) => ({
        url: `users/move/${id}`,
        method: "PATCH",
        body: data,
      }),
      onQueryStarted: async (
        { id }: { id: string },
        { dispatch, queryFulfilled }
      ) => {
        queryFulfilled.then(() => {
          dispatch(
            usersApi.util.updateQueryData(
              "getUsers",
              undefined,
              (draft: EntityState<GetManyUsersResponseElementDTO>) => {
                const { [id as keyof typeof draft.entities]: user, ...rest } =
                  draft.entities;
                return {
                  ...draft,
                  entities: {
                    ...rest,
                  },
                  ids: draft.ids.filter((userId) => userId !== id),
                };
              }
            )
          );
        });
      },
    }),
    getMe: builder.query<GetMeResponseDTO, void>({
      query: () => ({ url: "auth" }),
    }),
    updateMany: builder.mutation<
      UpdateUserResponseDTO,
      UpdateManyUsersRequestDTO
    >({
      query: ({
        applicationStatus,
        cardStatus,
        paymentStatus,
        awaitingReply,
        ids,
      }) => ({
        url: `users`,
        method: "PATCH",
        body: {
          applicationStatus,
          cardStatus,
          paymentStatus,
          awaitingReply,
          ids,
        },
      }),
      onQueryStarted: async (
        { ids, applicationStatus, cardStatus, paymentStatus, awaitingReply },
        { dispatch, queryFulfilled }
      ) => {
        const getUsersPatchResult = dispatch(
          usersApi.util.updateQueryData(
            "getUsers",
            undefined,
            (draft: EntityState<GetManyUsersResponseElementDTO>) => {
              const getEntityMapper =
                (
                  entities: EntityState<GetManyUsersResponseElementDTO>["entities"]
                ) =>
                (key: string): [string, GetManyUsersResponseElementDTO] => {
                  const id = key;
                  const user = entities[id];
                  if (!user) {
                    throw new Error("User not found");
                  }

                  if (ids.includes(id)) {
                    const newUser = { ...user };
                    if (applicationStatus) {
                      newUser.applicationStatus = applicationStatus;
                    }
                    if (cardStatus) {
                      newUser.cardStatus = cardStatus;
                    }

                    if (paymentStatus) {
                      newUser.paymentStatus = paymentStatus;
                    }

                    if (awaitingReply) {
                      newUser.awaitingReply = awaitingReply;
                    }

                    return [id, newUser];
                  }
                  return [id, user];
                };

              const entities =
                Object.fromEntries<GetManyUsersResponseElementDTO>(
                  Object.keys(draft.entities).map(
                    getEntityMapper(draft.entities)
                  )
                );

              return {
                ...draft,
                entities,
              };
            }
          )
        );

        const getUserPatchResult = ids.map((id) => {
          return dispatch(
            usersApi.util.updateQueryData(
              "getUser",
              id,
              (draft: GetOneUserResponseDTO) => {
                const newUser = { ...draft };
                if (applicationStatus) {
                  newUser["applicationStatus"] = applicationStatus;
                }
                if (cardStatus) {
                  newUser["cardStatus"] = cardStatus;
                }
                if (paymentStatus) {
                  newUser["paymentStatus"] = paymentStatus;
                }
                if (awaitingReply !== undefined) {
                  newUser["awaitingReply"] = awaitingReply;
                }

                return newUser;
              }
            )
          );
        });

        queryFulfilled.then((r) => {
          dispatch(
            usersApi.util.updateQueryData(
              "getUser",
              // if only one user is updated, update the cached user until it's fetched again
              ids[0],
              (draft: GetOneUserResponseDTO) => {
                return {
                  ...draft,
                  validUntil: r.data.validUntil,
                  dateCreated: r.data.dateCreated,
                };
              }
            )
          );
        });

        queryFulfilled.catch(() =>
          getUserPatchResult.forEach((patch: PatchCollection) => {
            patch.undo();
          })
        );
        queryFulfilled.catch(getUsersPatchResult.undo);
      },
    }),
    delete: builder.mutation<void, string>({
      query: (id) => ({
        url: `users/${id}`,
        method: "DELETE",
      }),
      onQueryStarted: async (id: string, { dispatch, queryFulfilled }) => {
        const getUsersPatchResult = dispatch(
          usersApi.util.updateQueryData(
            "getUsers",
            undefined,
            (draft: EntityState<GetManyUsersResponseElementDTO>) => {
              const { [id as keyof typeof draft.entities]: user, ...rest } =
                draft.entities;
              return {
                ...draft,
                entities: {
                  ...rest,
                },
                ids: draft.ids.filter((userId) => userId !== id),
              };
            }
          )
        );

        const getUserPatchResult = dispatch(
          usersApi.util.updateQueryData("getUser", id, () => {
            return undefined;
          })
        );

        queryFulfilled.catch(getUserPatchResult.undo);
        queryFulfilled.catch(getUsersPatchResult.undo);
      },
    }),
  }),
});

export const selectUsersResult = usersApi.endpoints.getUsers.select();
const selectUsersData = createSelector(
  selectUsersResult,
  (usersResult) => usersResult.data
);
export const { selectAll: selectAllUsers, selectById: selectUserById } =
  usersAdapter.getSelectors(
    (state: RootState) => selectUsersData(state) ?? initialState
  );

export const {
  useCreateUserMutation,
  useGetManyUsersByIdsMutation,
  useGetMeQuery,
  useGetUserQuery,
  useGetUsersQuery,
  useLazyGetMeQuery,
  useLazyGetUserQuery,
  useLazyGetUsersQuery,
  useMoveUserMutation,
  useUpdateManyMutation,
  useUpdateUserMutation,
  useLazySearchQuery,
  useSearchQuery,
  useDeleteMutation,
} = usersApi;
