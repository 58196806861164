import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlankOutlined as CheckBoxOutlineBlankOutlinedIcon,
  Close as CheckIcon,
  Check as CloseIcon,
  DeleteForever as DeleteForeverIcon,
  Outbound as OutboundIcon,
  WatchLater as WatchLaterIcon, Warning, HourglassBottom,
} from "@mui/icons-material";
import {
  Button as MuiButton,
  ButtonGroup as MuiButtonGroup,
  Grid as MuiGrid,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  MoveUserRequestDTO,
  UpdateUserResponseDTO,
  useDeleteMutation,
  useMoveUserMutation,
  useUpdateManyMutation,
} from "../redux/usersApi";
import { setSelectedUser } from "../redux/usersSlice";
import {ApplicationStatus, CardStatus, PaymentStatus} from "../typings/enums";
import DeleteForeverDialog from "./DeleteForeverDialog";
import MoveToPartnerCityDialog from "./MoveToPartnerCityDialog";
import { awaitingReplyLabels, paymentLabels } from "./labels";
import { withHidden } from "./withHidden";
export const Grid = withHidden(MuiGrid);
export const Button = withHidden(MuiButton);
export const ButtonGroup = withHidden(MuiButtonGroup);

export interface ActionPanelProps {
  applicationStatus: ApplicationStatus;
  paymentStatus: PaymentStatus;
  selectedUserId: string;
  isCardIssuer: boolean;
  isSuperAdmin: boolean;
  awaitingReply: boolean;
  dateCreated: string | null;
  user: Omit<
    UpdateUserResponseDTO,
    "id" | "applicationStatus" | "paymentStatus" | "awaitingReply"
  >;
}

export const ActionPanel = (props: ActionPanelProps) => {
  const [moveUser, { isLoading: isMoveUserLoading }] = useMoveUserMutation();
  const dispatch = useDispatch();
  const setSelectedUserId = (id: string | null) =>
    dispatch(setSelectedUser(id));

  const [updateMany, { isLoading: isUpdateLoading }] = useUpdateManyMutation();
  const [deleteForever, { isLoading: isDeleteLoading, isUninitialized }] =
    useDeleteMutation();

  const [isDeleteForeverDialogOpen, setIsDeleteForeverDialogOpen] =
    useState(false);

  const handleSubmitMove = async (
    values: Omit<MoveUserRequestDTO, "applicationStatus">
  ) => {
    if (!values.address || !values.postalCode) {
      return;
    }

    await moveUser({
      id: props.selectedUserId,
      data: {
        address: values.address,
        postalCode: values.postalCode,
        applicationStatus: ApplicationStatus.Pending,
      },
    });
    setSelectedUserId(null);
  };

  const handleRejectApplication = () =>
    updateMany({
      ids: [props.selectedUserId],
      applicationStatus: ApplicationStatus.Rejected,
    });

  const printReplacementCard = () =>
      updateMany({
        ids: [props.selectedUserId],
        cardStatus: CardStatus.NotPrinted,
      });

  const handleApproveApplication = () =>
    updateMany({
      ids: [props.selectedUserId],
      applicationStatus: ApplicationStatus.Approved,
    });

  const handlePendingApplication = () =>
      updateMany({
        ids: [props.selectedUserId],
        applicationStatus: ApplicationStatus.Pending,
      });

  const handleMoveToPartnerCity = () => {
    setMoveDialogOpen(true);
  };

  const handleTogglePayed = () => {
    if (props.paymentStatus === PaymentStatus.CaptureFailed) return; // Disable toggling for CaptureFailed

    updateMany({
      ids: [props.selectedUserId],
      paymentStatus:
          props.paymentStatus === PaymentStatus.Paid
              ? PaymentStatus.Unpaid
              : PaymentStatus.Paid,
    });
  };


  const handleSetAwaitingReply = () => {
    updateMany({
      ids: [props.selectedUserId],
      awaitingReply: props.awaitingReply ? false : true,
    });
  };

  const handleDeleteForever = async () => {
    setSelectedUserId(null);
    await deleteForever(props.selectedUserId);
    if (isDeleteForeverDialogOpen && !isUninitialized && !isDeleteLoading) {
      setIsDeleteForeverDialogOpen(false);
    }
  };

  const openDeleteDialog = () => {
    setIsDeleteForeverDialogOpen(true);
  };

  const [moveDialogOpen, setMoveDialogOpen] = useState(false);

  const paymentIcons = {
    [PaymentStatus.InProgress]: ({ disabled }: { disabled: boolean }) => (
      <WatchLaterIcon color={disabled ? "disabled" : "primary"} />
    ),
    [PaymentStatus.Paid]: ({ disabled }: { disabled: boolean }) => (
      <CheckBoxIcon color={disabled ? "disabled" : "primary"} />
    ),
    [PaymentStatus.Unpaid]: ({ disabled }: { disabled: boolean }) => (
      <CheckBoxOutlineBlankOutlinedIcon
        color={disabled ? "disabled" : "primary"}
      />
    ),
    [PaymentStatus.CaptureFailed]: ({ disabled }: { disabled: boolean }) => (
        <Warning color={disabled ? "disabled" : "error"} />
    ),
  };

  const PaymentIcon = paymentIcons[props.paymentStatus];
  const AwaitingReplyIcon = ({ disabled }: { disabled: boolean }) =>
    props.awaitingReply ? (
      <CheckBoxIcon color={disabled ? "disabled" : "primary"} />
    ) : (
      <CheckBoxOutlineBlankOutlinedIcon
        color={disabled ? "disabled" : "primary"}
        htmlColor="lightgray"
      />
    );

  return (
    <Grid container className="py-5" rowSpacing={2} columnSpacing={1}>
      <Grid item xs={12}>
        <ButtonGroup hidden={props.isCardIssuer === true || props.isSuperAdmin === true} fullWidth>
          <Button
              size="large"
              fullWidth
              variant={
                props.applicationStatus === ApplicationStatus.Pending
                    ? "contained"
                    : "outlined"
              }
              color={"primary"}
              startIcon={
                <HourglassBottom
                    htmlColor={
                      props.applicationStatus === ApplicationStatus.Pending
                          ? "white"
                          : "primary"
                    }
                />
              }
              onClick={handlePendingApplication}
          >
            Ausstehen
          </Button>
          <Button
            size="large"
            fullWidth
            variant={
              props.applicationStatus === ApplicationStatus.Approved
                ? "contained"
                : "outlined"
            }
            color={"primary"}
            startIcon={
              <CloseIcon
                htmlColor={
                  props.applicationStatus === ApplicationStatus.Approved
                    ? "white"
                    : "primary"
                }
              />
            }
            onClick={handleApproveApplication}
          >
            Genehmigen
          </Button>
          <Button
            hidden={props.isCardIssuer === true}
            size="large"
            fullWidth
            variant={
              props.applicationStatus === ApplicationStatus.Rejected
                ? "contained"
                : "outlined"
            }
            color="primary"
            startIcon={
              <CheckIcon
                htmlColor={
                  props.applicationStatus === ApplicationStatus.Rejected
                    ? "white"
                    : "primary"
                }
              />
            }
            onClick={handleRejectApplication}
          >
            Ablehnen
          </Button>
        </ButtonGroup>
      </Grid>
      <Grid item hidden={props.isCardIssuer === true || props.isSuperAdmin === true} xs={6}>
        <LoadingButton
            variant="outlined"
            size="large"
            fullWidth
            disabled={
                props.applicationStatus === ApplicationStatus.Rejected ||
                props.paymentStatus === PaymentStatus.InProgress ||
                props.paymentStatus === PaymentStatus.CaptureFailed // Disable when CaptureFailed
            }
            startIcon={
              <PaymentIcon
                  disabled={
                      props.applicationStatus === ApplicationStatus.Rejected ||
                      props.paymentStatus === PaymentStatus.InProgress ||
                      props.paymentStatus === PaymentStatus.CaptureFailed // Use disabled icon
                  }
              />
            }
            onClick={handleTogglePayed}
            loading={isUpdateLoading}
            sx={{
              color: props.paymentStatus === PaymentStatus.CaptureFailed ? 'error.main' : 'primary.main', // Set text color
              borderColor: props.paymentStatus === PaymentStatus.CaptureFailed ? 'error.main' : 'primary.main', // Set border color
              '&.Mui-disabled': {
                color: 'error.main', // Ensure color is red even when disabled
                borderColor: 'error.main', // Ensure border color is red even when disabled
              }
            }}
        >
          {props.paymentStatus === PaymentStatus.CaptureFailed // Show "Erfassung fehlgeschlagen" when CaptureFailed
              ? "Erfassung fehlgeschlagen"
              : paymentLabels[props.paymentStatus]}
        </LoadingButton>
      </Grid>
      <Grid item hidden={props.isCardIssuer === true || props.isSuperAdmin === true} xs={6}>
        <LoadingButton
          variant="outlined"
          size="large"
          fullWidth
          disabled={props.applicationStatus !== ApplicationStatus.Pending}
          startIcon={
            <AwaitingReplyIcon
              disabled={props.applicationStatus !== ApplicationStatus.Pending}
            />
          }
          onClick={handleSetAwaitingReply}
          loading={isUpdateLoading}
        >
          {
            awaitingReplyLabels[
              String(props.awaitingReply) as keyof typeof awaitingReplyLabels
            ]
          }
        </LoadingButton>
      </Grid>
      <Grid item hidden={props.isCardIssuer === true || props.isSuperAdmin === true} xs={6}>
        <Button
          size="large"
          fullWidth
          variant="outlined"
          color="primary"
          startIcon={<OutboundIcon color="primary" />}
          onClick={handleMoveToPartnerCity}
        >
          Umzug Partnergemeinde
        </Button>
      </Grid>
      <Grid item xs={6}>
        <ButtonGroup hidden={props.isCardIssuer === true || props.isSuperAdmin === true || props.user.cardStatus === CardStatus.NotPrinted} fullWidth>
          <Button
              hidden={props.isCardIssuer === true}
              size="large"
              fullWidth
              color="primary"
              onClick={printReplacementCard}
          >
            Ersatzkarte Drucken
          </Button>
        </ButtonGroup>
      </Grid>
      <Grid item xs={6}>
        <Button
          size="large"
          fullWidth
          variant="contained"
          color="error"
          startIcon={
            <DeleteForeverIcon
              color={
                props.applicationStatus === ApplicationStatus.Approved
                  ? "disabled"
                  : "inherit"
              }
            />
          }
          onClick={openDeleteDialog}
          hidden={props.isCardIssuer === true}
          disabled={props.applicationStatus === ApplicationStatus.Approved}
        >
          Antrag löschen
        </Button>
      </Grid>
      <DeleteForeverDialog
        isLoading={isDeleteLoading}
        deleteForever={handleDeleteForever}
        open={isDeleteForeverDialogOpen}
        handleClose={() => setIsDeleteForeverDialogOpen(false)}
        user={{ id: props.selectedUserId, ...props.user }}
      />
      <MoveToPartnerCityDialog
        open={moveDialogOpen}
        handleClose={() => setMoveDialogOpen(false)}
        isLoading={isMoveUserLoading}
        userId={props.selectedUserId}
        handleSubmitMove={handleSubmitMove}
      />
    </Grid>
  );
};
