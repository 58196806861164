export enum ApplicationStatus {
  Draft = "Draft",
  Approved = "Approved",
  Pending = "Pending",
  Rejected = "Rejected",
}

export enum CardStatus {
  NotPrinted = "NotPrinted",
  Printed = "Printed",
  Assigned = "Assigned",
  Sent = "Sent",
}

export enum PaymentStatus {
  Paid = "Paid",
  InProgress = "InProgress",
  Unpaid = "Unpaid",
  CaptureFailed = "CaptureFailed",
}

export enum Title {
  Divers = "divers",
  Man = "mr",
  Woman = "ms",
}

export enum Role {
  Tenant = "tenant",
  CardIssuer = "cardIssuer",
  SuperAdmin = "superAdmin",
}

export {};
