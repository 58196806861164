import { Box, CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import pick from "lodash.pick";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { RootState } from "../redux/store";
import {
  GetManyUsersResponseElementDTO,
  GetOneUserResponseDTO,
  UpdateUserResponseDTO,
  useGetMeQuery,
  useGetUsersQuery,
  useLazyGetUserQuery,
} from "../redux/usersApi";
import {
  setSelectedUser as setSelectedUserActionCreator,
  setSelectedUsers as setSelectedUsersActionCreator,
} from "../redux/usersSlice";
import BulkEditPanel from "./BulkEditPanel";
import DetailsPanel from "./DetailsPanel";
import UsersTable from "./UsersTable";

export default function GridLayout() {
  const selectedUserId = useSelector(
      (state: RootState) => state.users.selectedUserId
  );

  const selectedUsers = useSelector(
      (state: RootState) => state.users.selectedUsers
  );

  const dispatch = useDispatch();

  const setSelectedUsers = (users: string[]) =>
      dispatch(setSelectedUsersActionCreator(users));

  const setSelectedUserId = (id: string) =>
      dispatch(setSelectedUserActionCreator(id));

  const { data, isLoading, refetch } = useGetUsersQuery();
  const {
    currentData: loggedInUser,
    isLoading: isLoggedInUserLoading,
    isError,
  } = useGetMeQuery();
  const [fetchUser, result] = useLazyGetUserQuery();
  const navigate = useNavigate();

  const [retryCount, setRetryCount] = useState(0);

  useEffect(() => {
    if (isError) {
      navigate("/");
    } else {
      if (!data && retryCount < 3) {
        const retryTimeout = setTimeout(() => {
          refetch();
          setRetryCount((prevRetryCount) => prevRetryCount + 1);
        }, 2000);
        return () => clearTimeout(retryTimeout);
      }
    }
  }, [isError, navigate, refetch, data, retryCount]);

  if (isLoading || isLoggedInUserLoading) {
    return (
        <Grid
            container
            spacing={3}
            style={{
              height: "100vh",
              justifyContent: "center",
              alignItems: "center",
              margin: 0,
              padding: 0,
              width: "100vw",
            }}
        >
          <CircularProgress size={70} />
        </Grid>
    );
  }

  if (!data) {
    return <div>no data available after {retryCount} {retryCount === 1 ? "attempt" : "attempts"}</div>;
  }

  const rows = Object.values(data.entities);

  const handleFetchUser = (id: string) => {
    setSelectedUsers([id]);
    setSelectedUserId(id);
    return fetchUser(id);
  };

  // Define the SideBarComponent inline
  const SideBarComponent = () => {
    if (selectedUsers?.length > 1) {
      return (
          <BulkEditPanel
              selectedUsers={selectedUsers}
              setSelectionModel={setSelectedUsers}
          />
      );
    }

    if (!selectedUserId) {
      return (
          <Box>
            <Grid container rowSpacing={3} columnSpacing={2}>
              <Grid item xs={12}>
                Sie haben keinen Benutzer ausgewählt.
              </Grid>
            </Grid>
          </Box>
      );
    }

    if (result.currentData) {
      const fieldKeys: Readonly<Array<keyof UpdateUserResponseDTO>> = [
        "address",
        "rokkaHash",
        "dateCreated",
        "cardId",
        "cardStatus",
        "comment",
        "dateOfBirth",
        "email",
        "firstname",
        "image",
        "lastname",
        "phone",
        "postalCode",
        "title",
        "role",
        "orgAdmin",
      ];
      const fieldValues = pick<
          GetOneUserResponseDTO,
          keyof UpdateUserResponseDTO
          >(result.currentData, fieldKeys);

      return (
          <DetailsPanel
              selectedUserId={selectedUserId}
              rokkaHash={result.currentData.rokkaHash ?? ""}
              transactionId={result.currentData.transactionId}
              selectedUsers={selectedUsers}
              fieldValues={fieldValues}
              awaitingReply={result.currentData.awaitingReply}
              applicationStatus={result.currentData.applicationStatus}
              paymentStatus={result.currentData.paymentStatus}
              dateCreated={
                  result.currentData?.dateCreated
                      ? result.currentData.dateCreated
                      : null
              }
              transactionDate={
                result.currentData?.transactionDate
                    ? new Date(Number(result.currentData.transactionDate))
                    : null
              }
              validUntil={
                result.currentData?.validUntil
                    ? new Date(Number(result.currentData.validUntil))
                    : null
              }
          />
      );
    }

    if (result.error) {
      return <div>{JSON.stringify(result.error)}</div>;
    }

    return (
        <Box className="flex h-full items-center">
          <Grid container rowSpacing={3} className="flex" columnSpacing={2}>
            <Grid item xs={12} className="flex justify-center">
              <CircularProgress size={70} />
            </Grid>
          </Grid>
        </Box>
    );
  };

  return (
      <Grid
          container
          spacing={0} // No spacing between grid items
          style={{
            height: "100vh",
            margin: 0,
            padding: 0,
          }}
          className="flex-1"
      >
        <Grid item xs={selectedUsers.length === 0 ? 12 : 6} style={{ height: "100%" }}>
          <UsersTable
              rows={(rows as GetManyUsersResponseElementDTO[]) || []}
              fetchUser={handleFetchUser}
              loggedInUser={loggedInUser}
              loading={isLoading}
              setRowSelectionModel={(rowSelectionModel: string[]): void => {
                setSelectedUsers(rowSelectionModel);
              }}
              selectedUsers={selectedUsers}
          />
        </Grid>

        {selectedUsers.length > 0 && (
            <Grid item xs={6} style={{ height: "100%" }}>
              <SideBarComponent />
            </Grid>
        )}
      </Grid>
  );
}
