import { Box, Button } from "@mui/material";
import { FileUpload as FileUploadIcon } from "@mui/icons-material";
import { ChangeEvent, useState, useEffect } from "react";
import useDownscale from "../hooks/useDownscale";

export interface PictureUploaderProps {
    picture?: string;
    rokkaHash?: string;
    handleAttatchFile: (base64Image: string) => void;
}

const PictureUploader = (props: PictureUploaderProps) => {
    const { scaleCropImage } = useDownscale();

    if(props.rokkaHash === undefined) {
        props.rokkaHash = "";
    }
    const initialImageUrl = `https://einheimisch-ausweis.rokka.io/cards/${props.rokkaHash}.jpg`;
    const originalImageUrl = `https://einheimisch-ausweis.rokka.io/dynamic/noop/${props.rokkaHash}.jpg`;
    const [backgroundImage, setBackgroundImage] = useState<string>(() => {
        // Check if there's a saved image in localStorage on initial load
        const savedImage = props.rokkaHash ? localStorage.getItem(props.rokkaHash) : null;
        return savedImage ? `url(${savedImage})` : `url(${initialImageUrl})`;
    });

    useEffect(() => {
        // Set to default image if props.rokkaHash changes and there's no saved image
        const savedImage = localStorage.getItem(props.rokkaHash ?? "");
        if (!savedImage) {
            setBackgroundImage(`url(${initialImageUrl})`);
        }
    }, [props.rokkaHash, initialImageUrl]);

    const handleAddFile = async (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            const scaledImage = await scaleCropImage(file);

            const base64Image = await new Promise<string>((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(scaledImage);
                reader.onload = () => resolve(reader.result as string);
                reader.onerror = (error) => reject(error);
            });

            setBackgroundImage(`url(${base64Image})`);
            localStorage.setItem("backgroundImage", base64Image); // Store in localStorage
            props.handleAttatchFile(base64Image);
        }
    };

    return (
        <Box
            className="w-full rounded-md mb-10"
            sx={{
                backgroundImage: backgroundImage,
                backgroundSize: "contain",
                backgroundPosition: "left",
                backgroundRepeat: "no-repeat",
                minHeight: "300px",
            }}
        >
            <Button
                variant="outlined"
                component="label"
                fullWidth
                size="large"
                sx={{
                    opacity: props.rokkaHash && !localStorage.getItem(props.rokkaHash) ? "0%" : "100%",
                    height: "100%",
                    "&:hover": {
                        backgroundColor: "rgba(0,0,0,0.1)",
                        opacity: "100%",
                    },
                }}
            >
                <FileUploadIcon
                    htmlColor={props.picture ? "white" : "darkgray"}
                    fontSize="large"
                />
                <input
                    type="file"
                    accept="image/*"
                    title=" "
                    hidden
                    onChange={handleAddFile}
                />
            </Button>
            <Box hidden={!props.rokkaHash }>
                <Button
                    variant="contained"
                    color="primary"
                    href={originalImageUrl}
                    download="original_image.jpg"
                    sx={{
                        textDecoration: "none",
                        fontSize: "0.9em",
                    }}
                >
                    Originalbild herunterladen
                </Button>
            </Box>
        </Box>

    );
};

export default PictureUploader;
